import { FC, useContext, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import closeModal from '../../data/context/modal/actions/closeModal';
import modalContext from '../../data/context/modal/ModalProvider';
import useClickOutside from '../../hooks/clickOutside';
import Styled from './styles';

const ContainerModal: FC = () => {
  const { modalState, dispatchModal } = useContext(modalContext);
  const ref = useRef<HTMLInputElement>(null);

  useClickOutside(
    ref,
    () => closeModal(dispatchModal),
    modalState.clickOutSide,
  );

  const body: Element = document.body!;

  useEffect(() => {
    if (modalState.open) {
      body.classList.add('disabled-scroll');
    } else {
      body.classList.remove('disabled-scroll');
    }

    return () => body.classList.remove('disabled-scroll');
  }, [modalState.open]);

  if (!modalState.open || !modalState.template) return null;

  const el: Element = document.querySelector('#modal-root')!;

  return ReactDOM.createPortal(
    <Styled>
      <div ref={ref}>{modalState.template}</div>
    </Styled>,
    el,
  );
};

export default ContainerModal;
