import { FC, ChangeEvent } from 'react';
import { InputBaseProps, Size } from '../../models';
import Styled from './styles';

export interface Props extends InputBaseProps {
  theme?:
    | 'outlined-primary'
    | 'outlined-secondary'
    | 'outlined-tertiary'
    | 'outlined-active';
  size?: Size;
  checked?: boolean;
}

const CheckBox: FC<Props> = ({
  isHidden,
  theme = '',
  label = '',
  size = 'md',
  className = '',
  isDisabled = false,
  name = '',
  value,
  onChange,
  testId,
}) => {
  if (isHidden) return null;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange !== 'undefined') {
      onChange(event);
    }
  };

  return (
    <Styled
      theme={theme}
      size={size}
      className={className}
      isDisabled={isDisabled}
      data-testid={testId}>
      <input
        type="checkbox"
        disabled={isDisabled}
        name={name}
        checked={value || false}
        onChange={handleChange}
        data-testid={`${testId}-input`}
      />
      <span>
        {label && (
          <span data-label data-testid={`${testId}-label`}>
            {label}
          </span>
        )}
      </span>
    </Styled>
  );
};

export default CheckBox;
