import styled from 'styled-components';
import tw from 'twin.macro';

type TextareaProps = {
  isDisabled: boolean;
};

export default styled.div<TextareaProps>`
  ${tw`rounded p-4 bg-brand-u-secondary-color border-2 border-brand-u-secondary-border-color text-brand-u-secondary-text-color max-w-full`};

  ${({ isDisabled }) =>
    isDisabled &&
    tw`cursor-not-allowed opacity-70 border-brand-u-secondary-border-color`};
`;

export const Textarea = styled.textarea`
  width: 100%;
  background: transparent;
  outline: none;

  :disabled {
    ${tw`cursor-not-allowed`};
  }
`;
