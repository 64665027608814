import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'react-datepicker/dist/react-datepicker.css';
import './i18n/i18n';
import Provider from './data/context/Provider';
import ContainerModal from './components/Modals/ContainerModal';

ReactDOM.render(
  <React.Suspense fallback={<>Loading...</>}>
    <Provider>
      <App />
      <ContainerModal />
    </Provider>
  </React.Suspense>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
// reportWebVitals(console.log);
