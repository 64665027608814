/* eslint-disable import/no-named-as-default */
import { FC, useReducer } from 'react';
import ModalContext, { initialModalState } from './modal/ModalProvider';
import { PageErrorProvider } from './pageError/PageErrorProvider';
import UserContext, { initialUserState } from './user/UserContext';
import modalReducer from './modal/reducers/modal';
import userReducer from './user/reducers/user/user';

const Provider: FC = ({ children }) => {
  const [modalState, dispatchModal] = useReducer(modalReducer, initialModalState);
  const [userState, dispatchUser] = useReducer(userReducer, initialUserState);

  return (
    <UserContext.Provider value={{userState, dispatchUser}}>
      <ModalContext.Provider value={{modalState, dispatchModal}}>
        <PageErrorProvider>{children}</PageErrorProvider>
      </ModalContext.Provider>
    </UserContext.Provider>
  );
};

export default Provider;
