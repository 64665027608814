import { FC, FocusEvent } from 'react';
import StyledDatePicker from './styles';
import { InputBaseProps } from '../../models';

export interface Props extends InputBaseProps {
  value: any;
  onChangeDate: (date: Date) => void;
  onBlur?: (e: FocusEvent<any>) => void;
  dateFormat?: string;
}

const DatePick: FC<Props> = ({
  className,
  isHidden,
  isDisabled = false,
  onChangeDate,
  onBlur,
  name,
  value,
  dateFormat = 'yyyy-MM-dd',
}) => {
  if (isHidden) return null;

  const handleChange = (date: Date) => {
    if (typeof onChangeDate === 'function') {
      onChangeDate(date);
    }
  };

  const selectedDate = value ? new Date(value) : value;

  return (
    <>
      <StyledDatePicker
        onChange={handleChange}
        onBlur={onBlur}
        className={className}
        name={name}
        selected={selectedDate}
        dateFormat={dateFormat}
        disabled={isDisabled}
      />
    </>
  );
};

export default DatePick;
