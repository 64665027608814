import styled, { css } from 'styled-components/macro';
import tw from 'twin.macro';

type RadioButtonProps = {
  isDisabled: boolean;
  size: string;
  theme: string;
};

const variants: any = {
  secondary: {
    idle: {
      before: tw`block border-2 text-brand-u-secondary-border-color bg-brand-u-secondary-color rounded-full`,
    },
    checked: {
      before: tw`block border-brand-u-active-color  bg-brand-u-active-color rounded-full text-white`,
    },
  },
  active: {
    idle: {
      before: tw`block border-2 border-white rounded-full bg-neutral-white`,
    },
    checked: {
      before: tw`border-neutral-white bg-neutral-white rounded-full text-brand-u-active-color`,
    },
  },
};

const checkboxDefaultVariant = (variant: string) => css`
  ${tw`relative flex items-center`}
  &::before {
    content: '';
    width: 1.5em;
    height: 1.5em;
    flex-shrink: 0;
    border-color: currentColor;
    transition: box-shadow 0.2s cubic-bezier(0.51, 0.92, 0.24, 1),
      color 0.2s cubic-bezier(0.51, 0.92, 0.24, 1),
      transform 0.2s cubic-bezier(0.51, 0.92, 0.24, 1);
    ${variants[variant].idle.before};
  }
`;

const checkBoxDefaultVariantChecked = (variant: string) => css`
  &::before {
    ${variants[variant]?.checked?.before && variants[variant].checked.before};
    box-shadow: inset 0 0 0 0.15em currentColor;
  }
`;

export default styled.label<RadioButtonProps>`
  ${({ isDisabled }) =>
    isDisabled ? tw`cursor-not-allowed opacity-70` : tw`cursor-pointer`};
  ${tw`relative inline-flex items-center select-none`}
  font-size: 1em;
  ${({ size }) =>
    size === 'sm' &&
    css`
      font-size: 0.85em;
    `};
  ${({ size }) =>
    size === 'md' &&
    css`
      font-size: 1em;
    `};
  ${({ size }) =>
    size === 'lg' &&
    css`
      font-size: 1.125em;
    `};
  ${({ size }) =>
    size === 'xl' &&
    css`
      font-size: 1.225em;
    `};

  input {
    ${tw`appearance-none absolute w-full`}
    &:checked ~ span {
      ${checkBoxDefaultVariantChecked('secondary')}
    }
  }
  > span {
    ${checkboxDefaultVariant('secondary')};
  }

  [data-label] {
    ${tw`ml-2 text-brand-u-secondary-text-color`}
  }

  ${({ theme }) =>
    theme === 'outlined-secondary' &&
    css`
      & {
        ${tw`bg-brand-u-secondary-color border-2 border-brand-u-secondary-border-color p-3.5 rounded`}
        input {
          ${tw`appearance-none absolute w-full`}
          &:checked ~ span {
            ${checkBoxDefaultVariantChecked('secondary')}
          }
        }
        > span {
          ${checkboxDefaultVariant('secondary')};
        }
        [data-label] {
          ${tw`text-brand-u-secondary-text-color`}
        }
      }
    `};

  ${({ theme }) =>
    theme === 'outlined-active' &&
    css`
      & {
        ${tw`bg-brand-u-active-color border-2 border-brand-u-active-color p-3.5 rounded`}
        input {
          ${tw`appearance-none absolute w-full`}
          &:checked ~ span {
            ${checkBoxDefaultVariantChecked('active')}
          }
        }
        > span {
          ${checkboxDefaultVariant('active')};
        }
        [data-label] {
          ${tw`text-neutral-white`}
        }
      }
    `};
`;
