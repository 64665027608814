import { FC } from 'react';
import { InputBaseProps } from '../../models';
import Styled, { InputFile as Input } from './styles';

const InputFile: FC<InputBaseProps> = ({ className, isHidden, label, onChange, accept, testId }) => {

  if (isHidden) return null;

  return (
    <Styled className={className} data-testid={testId}>
      {label}
      <Input type="file" onChange={onChange} accept={accept} multiple />
    </Styled>
  );
};

export default InputFile;
