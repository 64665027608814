import styled from 'styled-components';
import tw from 'twin.macro';

export const WrapperHeader = styled.header`
  ${tw`p-4 bg-brand-u-brand-color`}
`;

export const HeaderContent = styled.header`
  ${tw`flex bg-brand-u-brand-color`}
  img {
    width: 56px;
    height: auto;
    flex-shrink: 0;
  }
`;
