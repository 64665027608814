import { FC, useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import 'twin.macro';
import Header from '../../components/Header/Header';
import CheckBox from '../../components/CheckBox/CheckBox';
import RadioButton from '../../components/RadioButton/RadioButton';
import DatePick from '../../components/DatePicker/DatePicker';
import userContext from '../../data/context/user/UserContext';
import { PageErrorContext } from '../../data/context/pageError/PageErrorProvider';
import PageErrorTemplate from '../../components/PageError/PageError';
import closePageError from '../../data/context/pageError/actions/closePageError';
import Spinner from '../../components/Spinner/Spinner';
import updateUser from '../../data/context/user/actions/user/updateUser';
import { User } from '../../models';
import useLocalStorage from '../../hooks/useLocalStorage';
import useFetch from '../../hooks/useFetch';
import LoadingModal from '../../components/Modals/components/LoadingModal/LoadingModal';
import openModal from '../../data/context/modal/actions/openModal';
import closeModal from '../../data/context/modal/actions/closeModal';
import modalContext from '../../data/context/modal/ModalProvider';
import MoockonModel from '../../data/models/MockoonModel';
import openPageError from '../../data/context/pageError/actions/openPageError';

const AuthFinishSignUp: FC = () => {
  const { push } = useHistory();
  const { userState, dispatchUser } = useContext(userContext);
  const [isLoading, setIsLoading] = useState(false);
  const { pagerErrorState, dispatchPageError } = useContext(PageErrorContext);
  const { dispatchModal } = useContext(modalContext);
  const { i18n, t } = useTranslation();

  const [, setLS] = useLocalStorage('step-1-complete');
  const [onFetch] = useFetch(
    () => {
      openModal({
        template: <LoadingModal />,
      })(dispatchModal);
    },
    () => {
      closeModal(dispatchModal);
    },
    (payload: any) => {
      updateUser({ user: payload.data })(dispatchUser);
    },
    () => {
      openPageError(dispatchPageError);
    },
    MoockonModel.moockerGetUser,
  );

  useEffect(() => {
    if (!isEmpty(userState?.user)) return;
    onFetch();
  }, [userState?.user]);

  useEffect(() => () => closeModal(dispatchModal), []);

  const SignupSchema = Yup.object({
    email: Yup.string()
      .email(t('VALIDATION_ERROR_EMAIL'))
      .required(t('VALIDATION_ERROR')),
    firstName: Yup.string().required(t('VALIDATION_ERROR')),
    lastName: Yup.string().required(t('VALIDATION_ERROR')),
    gender: Yup.string().required(t('VALIDATION_ERROR')),
    birthday: Yup.string().required(t('VALIDATION_ERROR')).nullable(),
  });

  //* TODO Comprobar si puede estar en esta página*/
  const onSubmit = async (values: any) => {
    //* TODO HACER aqui POST*/
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setLS(true);
      updateUser({ user: values })(dispatchUser);
      push(`/${i18n.language}/finishing-sign-up/user-type`);
    }, 2000);
  };

  const onTryAgainIfError = () => closePageError(dispatchPageError);

  return (
    <>
      <Header />
      {pagerErrorState.open ? (
        <PageErrorTemplate cb={onTryAgainIfError} />
      ) : (
        <main tw="p-4">
          <div tw="items-center mx-auto w-full mb-12 pt-6 max-w-screen-xl relative hidden md:flex ">
            <div tw="text-center  mx-auto">
              <p tw="font-poppins-regular text-lg font-bold mt-3">
                {t('PAGE_TITLE_1')}
              </p>
              <p tw="font-barlow-regular text-base mt-3">
                {t('PAGE_SUBTITLE_1')}
              </p>
            </div>
          </div>
          <div tw="flex flex-wrap justify-between items-center mx-auto w-full mb-12 pt-6 max-w-screen-xl relative md:hidden ">
            <div tw="text-center w-full mt-6">
              <p tw="font-poppins-regular text-lg font-bold mt-3">
                {t('PAGE_TITLE_1')}
              </p>
              <p tw="font-barlow-regular text-base mt-3">
                {t('PAGE_SUBTITLE_1')}
              </p>
            </div>
          </div>
          <div tw="py-4">
            <Formik
              initialValues={userState?.user as User}
              validationSchema={SignupSchema}
              enableReinitialize
              validateOnMount
              onSubmit={onSubmit}>
              {({
                values,
                errors,
                touched,
                isValid,
                handleChange,
                setFieldValue,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div tw="w-full flex flex-wrap mx-auto max-w-form-content-max">
                    <div tw="mb-2 w-full text-left sm:w-3/12 sm:text-right sm:mb-4">
                      <span tw="mr-3">{t('LABEL_EMAIL')}</span>
                    </div>
                    <div tw="mb-2 w-full sm:w-8/12 md:w-6/12 sm:mb-4">
                      <Input
                        tw="w-full"
                        name="email"
                        value={values?.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder={t('LABEL_EMAIL')}
                        isDisabled
                        type="email"
                      />
                      {errors.email && touched.email && (
                        <span tw="text-brand-u-brand-error pt-2 block">
                          {errors.email}
                        </span>
                      )}
                    </div>
                    <div tw="hidden md:block w-3/12 mb-4" />
                    <div tw="mb-2 w-full text-left sm:w-3/12 sm:text-right sm:mb-4">
                      <span tw="mr-3">{t('LABEL_FIRST_NAME')}</span>
                    </div>
                    <div tw="mb-2 w-full sm:w-8/12 md:w-6/12 sm:mb-4">
                      <Input
                        tw="w-full"
                        name="firstName"
                        value={values?.firstName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder={t('LABEL_FIRST_NAME')}
                        type="text"
                      />
                      {errors.firstName && touched.firstName && (
                        <span tw="text-brand-u-brand-error pt-2 block">
                          {errors.firstName}
                        </span>
                      )}
                    </div>
                    <div tw="hidden md:block w-3/12 mb-4" />
                    <div tw="mb-2 w-full text-left sm:w-3/12 sm:text-right sm:mb-4">
                      <span tw="mr-3">{t('LABEL_LAST_NAME')}</span>
                    </div>
                    <div tw="mb-2 w-full sm:w-8/12 md:w-6/12 sm:mb-4">
                      <Input
                        tw="w-full"
                        name="lastName"
                        value={values?.lastName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder={t('LABEL_LAST_NAME')}
                        type="text"
                      />
                      {errors.lastName && touched.lastName && (
                        <span tw="text-brand-u-brand-error pt-2 block">
                          {errors.lastName}
                        </span>
                      )}
                    </div>
                    <div tw="hidden md:block w-3/12 mb-4" />
                    <div tw="mb-2 w-full text-left sm:w-3/12 sm:text-right sm:mb-4">
                      <span tw="mr-3">{t('LABEL_GENDER')}</span>
                    </div>
                    <div tw="mb-2 w-full sm:w-8/12 md:w-6/12 sm:mb-4 md:flex md:space-x-2 block space-y-2 md:space-y-0">
                      <div tw="w-full">
                        <RadioButton
                          label={t('LABEL_MALE')}
                          tw="w-full h-full"
                          value="male"
                          name="gender"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          checked={values?.gender === 'male'}
                          theme={
                            values?.gender === 'male'
                              ? 'outlined-active'
                              : 'outlined-secondary'
                          }
                        />
                        {errors.gender && touched.gender && (
                          <span tw="text-brand-u-brand-error pt-2 block">
                            {errors.gender}
                          </span>
                        )}
                      </div>
                      <div tw="w-full">
                        <RadioButton
                          label={t('LABEL_FEMALE')}
                          value="female"
                          tw="w-full h-full"
                          name="gender"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          checked={values?.gender === 'female'}
                          theme={
                            values?.gender === 'female'
                              ? 'outlined-active'
                              : 'outlined-secondary'
                          }
                        />
                        {errors.gender && touched.gender && (
                          <span tw="text-brand-u-brand-error pt-2 block">
                            {errors.gender}
                          </span>
                        )}
                      </div>
                      <div tw="w-full">
                        <RadioButton
                          value="other"
                          label={t('LABEL_RATHER')}
                          tw="w-full h-full"
                          name="gender"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          checked={values?.gender === 'other'}
                          theme={
                            values?.gender === 'other'
                              ? 'outlined-active'
                              : 'outlined-secondary'
                          }
                        />
                        {errors.gender && touched.gender && (
                          <span tw="text-brand-u-brand-error pt-2 block">
                            {errors.gender}
                          </span>
                        )}
                      </div>
                    </div>
                    <div tw="hidden md:block w-3/12 mb-4" />
                    <div tw="mb-2 w-full text-left sm:w-3/12 sm:text-right sm:mb-4">
                      <span tw="mr-3">{t('LABEL_BIRTHDAY')}</span>
                    </div>
                    <div tw="mb-2 w-full sm:w-8/12 md:w-6/12 sm:mb-4">
                      <DatePick
                        tw="w-full"
                        value={values?.birthday}
                        onBlur={(e) => {
                          handleBlur(e);
                          setFieldValue('birthday', values?.birthday);
                        }}
                        onChangeDate={(date: Date) =>
                          setFieldValue('birthday', date)
                        }
                        name="birthday"
                      />
                      {errors.birthday && touched.birthday && (
                        <span tw="text-brand-u-brand-error pt-2 block">
                          {errors.birthday}
                        </span>
                      )}
                    </div>
                    <div tw="hidden md:block w-3/12 mb-4" />
                    <div tw="mb-2 w-full text-left sm:w-3/12 sm:text-right sm:mb-4" />
                    <div tw="mb-2 w-full sm:w-8/12 md:w-6/12 sm:mb-4">
                      <CheckBox
                        tw="mb-2 w-full"
                        label={t('LABEL_MARKETING_ACCEPTANCE')}
                        value={values?.settings?.marketing}
                        onChange={(e) =>
                          setFieldValue('settings.marketing', e.target.checked)
                        }
                        name="settings.marketing"
                      />
                    </div>
                    <div tw="hidden md:block w-3/12 mb-4" />
                    <div tw="mb-2 w-full text-left sm:w-3/12 sm:text-right sm:mb-4" />
                    <div tw="mb-2 w-full sm:w-8/12 md:w-6/12 sm:mb-4">
                      <Button
                        type="submit"
                        theme="primary"
                        tw="w-full sm:w-6/12 "
                        isDisabled={!isValid || isLoading}>
                        <span>{t('ACTION_CONTINUE')}</span>
                        {isLoading && (
                          <Spinner size="md" tw="ml-auto" theme="default" />
                        )}
                      </Button>
                    </div>
                    <div tw="hidden md:block w-3/12 mb-4" />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </main>
      )}
    </>
  );
};

export default AuthFinishSignUp;
