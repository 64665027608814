import { ChangeEvent, FC } from 'react';
import { InputBaseProps } from '../../models';
import Styled, { Textarea as StyledTextarea } from './styles';

export interface Props extends InputBaseProps {
  rows?: number;
}

const Textarea: FC<Props> = ({
  className = '',
  name = '',
  value = '',
  placeholder = '',
  rows,
  onChange,
  isHidden = false,
  isDisabled = false,
  testId,
}) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) onChange(event);
  };

  if (isHidden) return null;

  return (
    <Styled className={className} isDisabled={isDisabled} data-testid={testId}>
      <StyledTextarea
        name={name}
        placeholder={placeholder}
        value={value}
        rows={rows}
        onChange={handleChange}
        disabled={isDisabled}
        data-testid={`${testId}-input`}
      />
    </Styled>
  );
};

export default Textarea;
