import { FC } from 'react';
import Styled from './styles';

type SpinnerProps = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  isHidden?: boolean;
  testId?: string;
  theme?: 'active' | 'default';
};

const Spinner: FC<SpinnerProps> = ({
  size = 'md',
  isHidden = false,
  testId,
  className = '',
  theme = 'active',
}: SpinnerProps) => {
  if (isHidden) return null;

  return (
    <Styled
      size={size}
      data-testid={testId}
      className={className}
      theme={theme}
    />
  );
};

export default Spinner;
