import { createContext, Dispatch } from 'react';
import { User } from '../../../models/user';

type InitialUserStateType = { user: Partial<User> };

export const initialUserState = {
  user: {}
};

export interface UserContext {
  userState?: InitialUserStateType,
  dispatchUser: Dispatch<any>,
};

const userContext = createContext<UserContext>({
  userState: initialUserState,
  dispatchUser: () => null,
});

export default userContext;
