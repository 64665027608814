import { FC, ChangeEvent, FocusEvent } from 'react';
import { InputBaseProps, Size } from '../../models';
import Styled from './styles';

export interface Props extends InputBaseProps {
  theme?: 'outlined-secondary' | 'outlined-active';
  size?: Size;
  checked?: boolean;
  id?: any;
  onBlur?: (e: FocusEvent<any>) => void;
}

const RadioButton: FC<Props> = ({
  isHidden = false,
  checked = false,
  theme = '',
  label = '',
  size = 'md',
  className = '',
  isDisabled = false,
  name = '',
  id = '',
  value = '',
  onChange,
  onBlur,
  testId,
}) => {
  if (isHidden) return null;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange !== 'undefined') {
      onChange(event);
    }
  };

  return (
    <Styled
      theme={theme}
      size={size}
      className={className}
      isDisabled={isDisabled}
      data-testid={testId}>
      <input
        type="radio"
        disabled={isDisabled || false}
        name={name}
        id={id}
        value={value}
        checked={checked}
        onChange={handleChange}
        onBlur={onBlur}
        data-testid={`${testId}-input`}
      />
      <span>
        {label && (
          <span data-label data-testid={`${testId}-label`}>
            {label}
          </span>
        )}
      </span>
    </Styled>
  );
};

export default RadioButton;
