import React, { useEffect } from 'react';

const useClickOutside = (
  ref: React.RefObject<HTMLInputElement>,
  callback: { (): void; (): void },
  allowClickOutside: boolean | undefined,
) => {
  const handleClick = (e: any) => {
    if (!allowClickOutside) {
      return;
    }
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useClickOutside;
