import { FC, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { useHistory } from 'react-router';
import { useTranslation, Trans } from 'react-i18next';
import Button from '../Button/Button';
import Input from '../Input/Input';
import 'twin.macro';
import CheckBox from '../CheckBox/CheckBox';

import Spinner from '../Spinner/Spinner';

import FbIcon from '../../assets/img/icon_facebook.png';
import GIcon from '../../assets/img/icon_google.png';

const SignupForm: FC = () => {
  const { push } = useHistory();
  const [isCaptchaCorrect, setIsCaptchaCorrect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const recaptchaRef = useRef(null);
  const { i18n, t } = useTranslation();

  const SignupSchema = Yup.object({
    email: Yup.string()
      .email(t('VALIDATION_ERROR_EMAIL'))
      .required(t('VALIDATION_ERROR')),
    password: Yup.string()
      .required(t('VALIDATION_ERROR'))
      .min(6, t('VALIDATION_ERROR')),
  });

  const onSubmit = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      push(`/${i18n.language}/finishing-sign-up`);
    }, 2000);
  };

  const onChangeCaptcha = (value: string | null) =>
    setIsCaptchaCorrect(!!value);

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
          tos: false,
        }}
        validationSchema={SignupSchema}
        enableReinitialize
        validateOnMount
        onSubmit={onSubmit}>
        {({
          values,
          errors,
          touched,
          isValid,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <div
              tw="w-full mx-auto max-w-form-content-max"
              style={{ maxWidth: '507px' }}>
              <h1 tw="font-poppins-regular text-2xl font-bold mt-3 mb-10">
                {t('WELCOME_ERASMUSU')}
              </h1>
              <div tw="mb-4 text-left ">
                <span tw="font-barlow-regular font-bold text-base">
                  {t('SIGN_UP')}
                </span>
              </div>
              <div tw="mb-4">
                <Button type="button" theme="secondary" tw="w-full">
                  <div tw="w-full text-left flex items-center space-x-2">
                    <img src={FbIcon} alt="facebook" />
                    <span tw="text-brand-u-active-color font-sans">
                      {t('SIGN_UP_FACEBOOK')}
                    </span>
                  </div>
                </Button>
              </div>
              <div tw="mb-2">
                <Button type="button" theme="secondary" tw="w-full">
                  <div tw="w-full text-left flex items-center space-x-2">
                    <img src={GIcon} alt="google" />
                    <span tw="text-brand-u-active-color font-sans">
                      {t('SIGN_UP_GOOGLE')}
                    </span>
                  </div>
                </Button>
              </div>
              <div tw="flex justify-center py-6">
                <span tw="bg-brand-u-secondary-border-color h-0.5 w-[76px]" />
              </div>
              <div tw="mb-4">
                <span tw="font-barlow-regular font-bold text-base">
                  {t('SIGN_UP_EMAIL')}
                </span>
              </div>
              <div tw="mb-2 text-left">
                <span tw="font-sans">{t('LABEL_EMAIL')}</span>
              </div>
              <div tw="mb-2">
                <Input
                  tw="w-full"
                  name="email"
                  value={values?.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={t('LABEL_EMAIL')}
                  type="email"
                />
                {errors.email && touched.email && (
                  <span tw="text-brand-u-brand-error pt-2 block">
                    {errors.email}
                  </span>
                )}
              </div>
              <div tw="mb-2 text-left">
                <span tw="font-sans">{t('ACTION_CREATE_PASSWORD')}</span>
              </div>
              <div tw="mb-4">
                <Input
                  tw="w-full"
                  name="password"
                  value={values?.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder=""
                  type="password"
                />
                {errors.password && touched.password && (
                  <span tw="text-brand-u-brand-error pt-2 block">
                    {errors.password}
                  </span>
                )}
              </div>
              <div tw="mb-4 w-full flex items-center space-x-2">
                <CheckBox
                  label=""
                  value={values?.tos}
                  onChange={(e) => setFieldValue('tos', e.target.checked)}
                  name="tos"
                />
                <span tw="font-barlow-regular text-base">
                  <Trans i18nKey="ACCEPT_TOS">
                    <a
                      href="https://erasmusu.com/es/legal"
                      rel="noreferrer"
                      target="_blank"
                      tw="font-barlow-regular text-base underline">
                      Terms of Use
                    </a>
                    <a
                      href="https://erasmusu.com/es/legal#section-13"
                      rel="noreferrer"
                      target="_blank"
                      tw="font-barlow-regular text-base underline">
                      Privacy and Cookies Policy
                    </a>
                  </Trans>
                </span>
              </div>
              <div tw="mb-4">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LegxPwUAAAAAMPHclG8ZcaHowWzHfNR8kvlTXgY"
                  onChange={onChangeCaptcha}
                />
              </div>
              <div tw="mb-2">
                <Button
                  type="submit"
                  theme="primary"
                  tw="w-full"
                  isDisabled={!isValid || !isCaptchaCorrect}>
                  <span>{t('ACTION_CONTINUE')}</span>
                  {isLoading && (
                    <Spinner size="md" tw="ml-auto" theme="default" />
                  )}
                </Button>
              </div>
              <div tw="mb-2 mt-6">
                <p tw="font-barlow-regular text-base text-center">
                  <Trans i18nKey="ALREADY_IN_ERASMUSU">
                    <a
                      href="https://erasmusu.com"
                      tw="font-barlow-regular text-base font-bold text-brand-u-active-color underline">
                      Log in
                    </a>
                  </Trans>
                </p>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SignupForm;
