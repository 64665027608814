import { ButtonHTMLAttributes, FC, MouseEvent } from 'react';
import { BaseProps, Size } from '../../models';
import Styled from './styles';

export interface Props extends BaseProps {
  onClick?: (event: MouseEvent) => void;
  theme?: 'primary' | 'secondary' | 'tertiary' | 'link' | 'active' | 'skip';
  size?: Size;
  type?: ButtonHTMLAttributes<any>['type'];
  isDisabled?: boolean;
}

const Button: FC<Props> = ({
  children,
  className,
  isHidden,
  onClick,
  theme,
  size = 'md',
  type = 'button',
  isDisabled = false,
  testId,
}) => {
  if (isHidden) return null;

  return (
    <Styled
      className={className}
      type={type}
      theme={theme}
      size={size}
      onClick={onClick}
      disabled={isDisabled}
      data-testid={testId}>
      {children}
    </Styled>
  );
};

export default Button;
