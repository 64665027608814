import {
  MODAL_OPEN,
  MODAL_CLOSE,
} from '../../../../config/constants/actionTypes';

const modalReducer = (state: any, { payload, type }: any) => {
  switch (type) {
    case MODAL_OPEN:
    case MODAL_CLOSE:
      return { ...payload };
    default:
      return state;
  }
};

export default modalReducer;
