import { FC, useContext, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { Formik } from 'formik';
import userContext from '../../data/context/user/UserContext';
import Button from '../../components/Button/Button';
import Header from '../../components/Header/Header';
import 'twin.macro';
import PhotoUploader from '../../components/PhotoUploader/PhotoUploader';
import ROLE_TYPES from '../../config/constants/roleTypes';
import { User } from '../../models';
import useFetch from '../../hooks/useFetch';
import openModal from '../../data/context/modal/actions/openModal';
import LoadingModal from '../../components/Modals/components/LoadingModal/LoadingModal';
import closeModal from '../../data/context/modal/actions/closeModal';
import openPageError from '../../data/context/pageError/actions/openPageError';
import updateUser from '../../data/context/user/actions/user/updateUser';
import modalContext from '../../data/context/modal/ModalProvider';
import { PageErrorContext } from '../../data/context/pageError/PageErrorProvider';
import MoockonModel from '../../data/models/MockoonModel';
import PageErrorTemplate from '../../components/PageError/PageError';
import closePageError from '../../data/context/pageError/actions/closePageError';

interface StateType {
  from?: string | null;
}

const SignupSchema = Yup.object().shape({
  avatar: Yup.mixed(),
});

const AuthUploadPhoto: FC = () => {
  const { userState, dispatchUser } = useContext(userContext);
  const { dispatchModal } = useContext(modalContext);
  const { pagerErrorState, dispatchPageError } = useContext(PageErrorContext);
  const [isLoading, setIsLoading] = useState(false);
  const { push } = useHistory();
  const { i18n, t } = useTranslation();
  const location = useLocation<StateType>();
  const [onFetch] = useFetch(
    () => {
      openModal({
        template: <LoadingModal />,
      })(dispatchModal);
    },
    () => {
      closeModal(dispatchModal);
    },
    (payload: any) => {
      updateUser({ user: payload.data })(dispatchUser);
    },
    () => {
      openPageError(dispatchPageError);
    },
    MoockonModel.moockerGetUser,
  );

  useEffect(() => {
    if (!isEmpty(userState?.user)) return;
    onFetch();
  }, [userState?.user]);

  useEffect(() => () => closeModal(dispatchModal), []);

  const BACK_PATH = useMemo(
    () => ({
      [ROLE_TYPES.UNKNOW_STUDENT]: `/${i18n.language}/finishing-sign-up/student/3`,
      [ROLE_TYPES.LANDLORD]: `/${i18n.language}/finishing-sign-up/landlord/1`,
      [ROLE_TYPES.OTHER]: `/${i18n.language}/finishing-sign-up/user-type`,
    }),
    [i18n.language],
  );

  // TODO Averiguar de que ruta viene, ya que puede venir desde landlord o desde student/
  const onSkipStep = () => push(`/${i18n.language}/finishing-sign-up/bio`);

  const onBackStep = () => {
    if (location?.state?.from) {
      push(BACK_PATH[location.state.from]);
    } else {
      push(`/${i18n.language}/finishing-sign-up/user-type`);
    }
  };

  const onSubmit = async (values: any) => {
    // Obtener el values => image => e.target.files[0]
    const formData = new FormData();
    formData.append('avatar', values);

    try {
      setIsLoading(true);
      setTimeout(() => {
        // TODO LLAMADA API PENDING para subir fichero
        // const response = await FilesModel.uploadImage(formData);
        // La api devuelve un photocode, que es lo que debemos guardar
        setIsLoading(false);
        // updateUser({ user: values })(dispatchUser);
        push(`/${i18n.language}/finishing-sign-up/bio`);
      }, 2000);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('step-1-complete')) {
      push(`/${i18n.language}/finishing-sign-up`);
    } else if (!localStorage.getItem('step-2-complete')) {
      push(`/${i18n.language}/finishing-sign-up/user-type`);
    }
  }, [localStorage]);

  const onTryAgainIfError = () => closePageError(dispatchPageError);

  return (
    <>
      <Header />
      {pagerErrorState.open ? (
        <PageErrorTemplate cb={onTryAgainIfError} />
      ) : (
        <main tw="p-4">
          <div tw="items-center mx-auto w-full mb-12 pt-6 max-w-screen-xl relative hidden md:flex ">
            <button
              type="button"
              tw="absolute left-0 cursor-pointer"
              onClick={onBackStep}>
              <MdKeyboardArrowLeft tw="text-icon text-neutral-dark3" />
            </button>
            <div tw="text-center mx-auto">
              <p tw="font-poppins-regular text-lg font-bold mt-3">
                {t('PAGE_TITLE_6')}
              </p>
              <p tw="font-barlow-regular text-base mt-3">
                {t('PAGE_SUBTITLE_6')}
              </p>
            </div>
            <div tw="absolute right-0">
              <Button onClick={onSkipStep} theme="skip" size="sm">
                {t('ACTION_SKIP_STEP')}
              </Button>
            </div>
          </div>
          <div tw="flex flex-wrap justify-between items-center mx-auto w-full mb-12 pt-6 max-w-screen-xl relative md:hidden ">
            <button type="button" tw="cursor-pointer" onClick={onBackStep}>
              <MdKeyboardArrowLeft tw="text-icon text-neutral-dark3" />
            </button>
            <div>
              <Button onClick={onSkipStep} theme="skip" size="sm">
                {t('ACTION_SKIP_STEP')}
              </Button>
            </div>
            <div tw="text-center w-full mt-6">
              <p tw="font-poppins-regular text-lg font-bold mt-3">
                {t('PAGE_TITLE_6')}
              </p>
              <p tw="font-barlow-regular text-base mt-3">
                {t('PAGE_SUBTITLE_6')}
              </p>
            </div>
          </div>
          <div>
            <Formik
              initialValues={userState?.user as User}
              validationSchema={SignupSchema}
              onSubmit={onSubmit}>
              {({ handleSubmit, values, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <PhotoUploader
                    image={values?.avatar}
                    isLoading={isLoading}
                    onChange={(image: any) => setFieldValue('avatar', image)}
                  />
                </form>
              )}
            </Formik>
          </div>
        </main>
      )}
    </>
  );
};

export default AuthUploadPhoto;
