import { FC, ChangeEvent, ReactNode, FocusEvent } from 'react';
import { InputBaseProps } from '../../models';
import { StyledInput, StyledIcon } from './styles';

export interface Props extends InputBaseProps {
  onBlur?: (e: FocusEvent<any>) => void;
  onClick?: () => void;
  type?: string;
  icon?: ReactNode;
  iconPosition?: string;
}

const Input: FC<Props> = ({
  className,
  isHidden,
  type = 'text',
  isDisabled = false,
  testId,
  onChange,
  onBlur,
  onClick,
  name,
  value = '',
  placeholder = '',
  icon,
  iconPosition = 'right',
}) => {
  if (isHidden) return null;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange !== 'undefined') {
      onChange(event);
    }
  };

  return (
    <>
      <StyledInput
        className={className}
        iconPosition={iconPosition}
        data-testid={testId}>
        <input
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          onClick={onClick}
          disabled={isDisabled}
          placeholder={placeholder}
          data-testid={`${testId}-input`}
        />
        {icon && <StyledIcon data-testid={`${testId}-icon`}>{icon}</StyledIcon>}
      </StyledInput>
    </>
  );
};

export default Input;
