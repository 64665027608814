import { FC } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'twin.macro';
import AuthClient from './pages/AuthClient';
import GlobalStyles from './styles/GlobalStyles';

const App: FC = () => {
  const locale = '/:locale(es|en|de|fr|it|nl|pl|pt|tr)?';

  return (
    <>
      <GlobalStyles />
      <Router>
        <Switch>
          <Route
            exact
            path={`${locale}/signup`}
            component={AuthClient.AuthSignUp}
          />
          <Route
            exact
            path={`${locale}/finishing-sign-up`}
            component={AuthClient.AuthFinishSignUp}
          />
          <Route
            exact
            path={`${locale}/finishing-sign-up/user-type`}
            component={AuthClient.AuthUserType}
          />
          <Route
            exact
            path={`${locale}/finishing-sign-up/landlord/1`}
            component={AuthClient.AuthLandLord1}
          />
          <Route
            exact
            path={`${locale}/finishing-sign-up/student/1`}
            component={AuthClient.AuthStudent1}
          />
          <Route
            exact
            path={`${locale}/finishing-sign-up/student/2`}
            component={AuthClient.AuthStudent2}
          />
          <Route
            exact
            path={`${locale}/finishing-sign-up/student/3`}
            component={AuthClient.AuthStudent3}
          />
          <Route
            exact
            path={`${locale}/finishing-sign-up/upload-photo`}
            component={AuthClient.AuthUploadPhoto}
          />
          <Route
            exact
            path={`${locale}/finishing-sign-up/bio`}
            component={AuthClient.AuthBio}
          />
          <Route path={`${locale}`} exact component={AuthClient.AuthSignUp} />
          <Route path="*" exact component={AuthClient.AuthSignUp} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
