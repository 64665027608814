import { MODAL_CLOSE } from '../../../../config/constants/actionTypes';

export default (
  dispatch: (arg0: {
    type: string;
    payload: { open: boolean; message: null };
  }) => void,
) => {
  dispatch({
    type: MODAL_CLOSE,
    payload: { open: false, message: null },
  });
};
