import { FC } from 'react';
import _ from 'lodash';
import 'twin.macro';
import { IoBugOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import Styled, { PageErrorBox, IconWrapper } from './styles';
import Button from '../Button/Button';

type PageErrorTypes = {
  cb?: () => void;
};

const PageErrorTemplate: FC<PageErrorTypes> = ({ cb }: PageErrorTypes) => {
  const { t } = useTranslation();

  return (
    <Styled>
      <PageErrorBox>
        <p tw="text-lg font-bold font-poppins-regular text-center">
          {t('MESSAGE_ERROR_UNEXPECTED')}
        </p>
        <IconWrapper>
          <IoBugOutline />
        </IconWrapper>
        <p tw="text-base my-5 font-poppins-regular">
          {t('MESSAGE_ERROR_UNEXPECTED')}. {t('ACTION_PLEASE_TRY_AGAIN')}
        </p>
        <div tw="w-full my-5">
          <Button
            type="button"
            theme="primary"
            tw="w-full"
            onClick={() => cb && cb()}>
            {_.upperFirst(t('ACTION_TRY_AGAIN'))}
          </Button>
        </div>
      </PageErrorBox>
    </Styled>
  );
};

export default PageErrorTemplate;
