import AxiosService from '../../servicies/axios.service';
import Config from '../../config';

const moockerGetCountries = async payload => AxiosService.get(`${Config.server.api.moocker}/country/${payload}`, {}, false);
const moockerGetUser = async () => AxiosService.get(`${Config.server.api.moocker}/user`, {}, false);

const model = {
    moockerGetCountries,
    moockerGetUser
}

export default model;