import AuthSignUp from './AuthSignUp';
import AuthFinishSignUp from './AuthFinishSignUp';
import AuthUserType from './AuthUserType';
import AuthLandLord1 from './AuthLandLord1';
import AuthStudent1 from './AuthStudent1';
import AuthStudent2 from './AuthStudent2';
import AuthStudent3 from './AuthStudent3';
import AuthUploadPhoto from './AuthUploadPhoto';
import AuthBio from './AuthBio';

export default {
  AuthSignUp,
  AuthFinishSignUp,
  AuthUserType,
  AuthLandLord1,
  AuthStudent1,
  AuthStudent2,
  AuthStudent3,
  AuthUploadPhoto,
  AuthBio,
};
