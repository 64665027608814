import styled from 'styled-components';
import tw from 'twin.macro';

export default styled.div`
  ${tw`w-full grid place-items-center`};
`;

export const PageErrorBox = styled.div`
  max-width: 500px;
  ${tw`w-full bg-white relative p-8 flex flex-col items-start text-lg text-gray-800`};
`;

export const IconWrapper = styled.div`
  ${tw`w-full flex justify-center items-center pt-6`};
  font-size: 5.75rem;
`;
