import { FC, createContext, useReducer, ReactNode, Dispatch } from 'react';
import pageErrorReducer from './reducers/pageError';

type GlobalProviderProps = {
  children: ReactNode;
};

type InitialStateType = {
  open: boolean;
};

const initialState = {
  open: false,
};

export const PageErrorContext = createContext<{
  pagerErrorState: InitialStateType;
  dispatchPageError: Dispatch<any>;
}>({
  pagerErrorState: initialState,
  dispatchPageError: () => null,
});

export const PageErrorProvider: FC<GlobalProviderProps> = ({ children }) => {
  const [pagerErrorState, dispatchPageError] = useReducer(
    pageErrorReducer,
    initialState,
  );

  return (
    <PageErrorContext.Provider value={{ pagerErrorState, dispatchPageError }}>
      {children}
    </PageErrorContext.Provider>
  );
};
