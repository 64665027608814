import styled from 'styled-components';
import tw from 'twin.macro';

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  position: relative;
`;

export const RemoveButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  
  ${tw`rounded-full h-10 w-10 bg-neutral-black`};
`;

export const Image = styled.img`
  border-radius: 100%;
  width: 192px;
  height: 192px;
  margin-bottom: 40px;
`;