import { createGlobalStyle } from 'styled-components';
import tw, { GlobalStyles as BaseStyles } from 'twin.macro';

const CustomStyles = createGlobalStyle`

  :root {
    --u-primary-color: #FA5A00; 
    --u-primary-border-color: #A03B01; 
    --u-secondary-color: #F2F2F2;
    --u-secondary-border-color:#627793;
    --u-secondary-text-color:#627793;
    --u-tertiary-color: #242131;
    --u-tertiary-border-color:#242131;
    --u-disabled-color: #C5C5C5;
    --u-disabled-border-color:#C5C5C5;
    --u-active-color: #1154FF;
    --u-brand-color: #44C1DC;
    --u-brand-error: #B72929;
  }

  body {
    ${tw`antialiased`}
  }

  #root {
      width:100%;
      height:100%;
  }

  .disabled-scroll {
    overflow: hidden;
  }

`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;

// TODO: Mirar porque no se puede interpolar theme
// -webkit-tap-highlight-color: ${theme() ? theme`colors.purple.500` : ''};
