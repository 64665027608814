import {
  FC,
  useCallback,
  PropsWithChildren,
  ReactNode,
  FocusEvent,
} from 'react';
import { components, DropdownIndicatorProps, GroupBase } from 'react-select';
import StyledAsyncSearch from './styles';

type Props = {
  isHidden?: boolean;
  apiTarget(payload: any): any;
  onResultSelect(value: any): void;
  onFilter(value: any): void;
  getOptionValue?(value: any): any;
  onInputChange?(value: any): any;
  onBlur?: (e: FocusEvent<any>) => void;
  resultRenderer: any;
  icon?: ReactNode;
  noOptionsMessage?: string;
  loadingMessage?: string;
  placeholder?: string;
  value: any;
  name?: any;
};

const DropdownIndicator = (
  props: PropsWithChildren<
    DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>
  >,
  icon: ReactNode,
) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.DropdownIndicator {...props}>
    <div style={{ fontSize: '1.5em' }}>{icon}</div>
  </components.DropdownIndicator>
);

/** *
 * * AsyncSearcher/Search COMPONENT
 * @required @params
 * @param apiTarget Function => Modelo.método de la api para obtener los datos
 * @param onFilter Function => Predicado de cómo se deben filtrar los datos
 * @param onResultSelect Function => Devuelve valor de item seleccionado
 * @param onResultRenderer => Function => Layout de resultados
 */

const AsyncSearcher: FC<Props> = ({
  isHidden = false,
  apiTarget,
  onResultSelect,
  onFilter,
  getOptionValue,
  resultRenderer,
  icon = null,
  noOptionsMessage = '',
  loadingMessage = '',
  placeholder = '',
  value,
  name = '',
  onBlur,
  onInputChange,
}) => {
  if (isHidden) return null;

  const loadOptions = useCallback(
    async (inputValue, callback) => {
      try {
        const response = await apiTarget(inputValue);

        if (response.data && response.data.length === 0) {
          callback([]);
        }

        callback(() => onFilter(response));
      } catch (error) {
        callback([]);
      }
    },
    [apiTarget],
  );

  return (
    <StyledAsyncSearch
      loadOptions={loadOptions}
      classNamePrefix="filter"
      id={name}
      onChange={(option) => onResultSelect(option)}
      formatOptionLabel={resultRenderer}
      noOptionsMessage={() => noOptionsMessage}
      loadingMessage={() => loadingMessage}
      placeholder={placeholder}
      getOptionValue={getOptionValue}
      onInputChange={onInputChange}
      onBlur={(e: FocusEvent<any>) => {
        if (typeof onBlur === 'function') {
          onBlur(e);
        }
      }}
      value={value}
      components={{
        DropdownIndicator: (params) => DropdownIndicator(params, icon),
        IndicatorSeparator: () => null,
      }}
    />
  );
};

export default AsyncSearcher;
