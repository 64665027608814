import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IoClose } from 'react-icons/io5';
import { BaseProps } from '../../models';
import Styled, { Container, RemoveButton, Image } from './styles';
import Button from '../Button/Button';
import InputFile from '../InputFile/InputFile';
import 'twin.macro';
import Spinner from '../Spinner/Spinner';

export interface Props extends BaseProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  image: any;
  isLoading?: boolean;
}

const PhotoUploader: FC<Props> = ({
  className,
  isHidden,
  testId,
  isLoading = false,
  image: initialImage,
  onChange,
}) => {
  const { t } = useTranslation();

  const [image, setImage] = useState(initialImage ?? '');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      const nextImage = event.target.files[0] as any;
      setImage(URL.createObjectURL(nextImage));

      if (onChange) onChange(nextImage);
    }
  };

  const handleRemove = () => setImage('');

  const removeButton = image && (
    <RemoveButton onClick={handleRemove} data-testid={`${testId}-remove-button`}>
      <IoClose tw="text-white" size="32px" />
    </RemoveButton>
  )

  if (isHidden) return null;

  return (
    <Styled className={className} data-testid={testId}>
      <Container>
        {removeButton}
        <Image
          src={image || '/assets/images/placeholder-uploader.svg'}
          alt="avatar profile"
          data-testid={`${testId}-image`}
        />
      </Container>
      <InputFile
        tw="w-96"
        accept="image/png, image/jpeg"
        label={t('ACTION_UPLOAD_PICTURE')}
        onChange={handleChange}
        isHidden={!!image}
        testId={`${testId}-input`}
      />
      <Button
        theme="primary"
        tw="w-full sm:w-96"
        type="submit"
        isHidden={!image}
        isDisabled={isLoading}
        testId={`${testId}-upload-button`}>
        {t('ACTION_SAVE_CONTINUE')}
        {isLoading && <Spinner size="md" tw="ml-auto" theme="default" />}
      </Button>
    </Styled>
  );
};

export default PhotoUploader;
