import styled from 'styled-components';
import tw from 'twin.macro';

type ButtonProps = {
  size: string;
  theme: string;
};

export default styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  padding: 16px 24px;
  line-height: 20px;
  border: 2px solid transparent;
  cursor: pointer;
  ${tw`bg-neutral-white border-brand-u-tertiary-color text-brand-u-tertiary-color`};

  ${({ theme }) =>
    theme === 'primary' &&
    tw`bg-brand-u-primary-color border-brand-u-primary-border-color text-neutral-white`};
  ${({ theme }) =>
    theme === 'secondary' &&
    tw`bg-brand-u-secondary-color border-brand-u-secondary-border-color text-brand-u-secondary-text-color`};
  ${({ theme }) =>
    theme === 'tertiary' &&
    tw`bg-brand-u-tertiary-color border-brand-u-tertiary-border-color text-neutral-white`};
  ${({ theme }) =>
    theme === 'link' &&
    tw`bg-brand-u-secondary-color border-brand-u-secondary-color text-brand-u-active-color`};
  ${({ theme }) =>
    theme === 'active' &&
    tw`bg-brand-u-active-color border-brand-u-active-color text-neutral-white`};

  ${({ theme }) =>
    theme === 'skip' &&
    tw`bg-brand-u-disabled-color border-brand-u-disabled-border-color text-neutral-white`}

  ${({ size }) => size === 'sm' && tw`px-2 py-2`};
  ${({ size }) => size === 'lg' && tw`px-10 py-5`};
  ${({ size }) => size === 'xl' && tw`px-12 py-6`};

  :disabled {
    ${tw`cursor-not-allowed bg-brand-u-disabled-color border-brand-u-disabled-border-color text-neutral-white`}
  }
`;
