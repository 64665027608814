import { createContext, Dispatch } from 'react';

type InitialStateType = {
  open: boolean;
  clickOutSide?: boolean;
  template: any;
};

export const initialModalState = {
  open: false,
  clickOutSide: false,
  template: null,
};

export interface ModalContext {
  modalState: InitialStateType;
  dispatchModal: Dispatch<any>;
};

const modalContext = createContext<ModalContext>({
  modalState: initialModalState,
  dispatchModal: () => null
});

export default modalContext;
