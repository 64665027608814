import { FC, useContext, useEffect, useState } from 'react';
import { isEmpty, set } from 'lodash';
import { Formik, getIn } from 'formik';
import { MdKeyboardArrowLeft, MdSearch } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button from '../../components/Button/Button';
import 'twin.macro';
import Header from '../../components/Header/Header';
import RadioButton from '../../components/RadioButton/RadioButton';
import AsyncSearcher from '../../components/AsyncSearcher/AsyncSearcher';
import MoockonModel from '../../data/models/MockoonModel';
import userContext from '../../data/context/user/UserContext';
import Spinner from '../../components/Spinner/Spinner';
import updateUser from '../../data/context/user/actions/user/updateUser';
import { User } from '../../models';
import useFetch from '../../hooks/useFetch';
import openModal from '../../data/context/modal/actions/openModal';
import LoadingModal from '../../components/Modals/components/LoadingModal/LoadingModal';
import openPageError from '../../data/context/pageError/actions/openPageError';
import closeModal from '../../data/context/modal/actions/closeModal';
import { PageErrorContext } from '../../data/context/pageError/PageErrorProvider';
import modalContext from '../../data/context/modal/ModalProvider';
import PageErrorTemplate from '../../components/PageError/PageError';
import closePageError from '../../data/context/pageError/actions/closePageError';

const AuthStudent2: FC = () => {
  const { userState, dispatchUser } = useContext(userContext);
  const { dispatchModal } = useContext(modalContext);
  const { pagerErrorState, dispatchPageError } = useContext(PageErrorContext);
  const [isLoading, setIsLoading] = useState(false);
  const { push } = useHistory();
  const { i18n, t } = useTranslation();
  const [onFetch] = useFetch(
    () => {
      openModal({
        template: <LoadingModal />,
      })(dispatchModal);
    },
    () => {
      closeModal(dispatchModal);
    },
    (payload: any) => {
      updateUser({ user: payload.data })(dispatchUser);
    },
    () => {
      openPageError(dispatchPageError);
    },
    MoockonModel.moockerGetUser,
  );

  useEffect(() => {
    if (!isEmpty(userState?.user)) return;
    onFetch();
  }, [userState?.user]);

  useEffect(() => () => closeModal(dispatchModal), []);

  const onSkipStep = () =>
    push(`/${i18n.language}/finishing-sign-up/student/3`);
  const onBackStep = () =>
    push(`/${i18n.language}/finishing-sign-up/student/1`);

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.student?.studentAnswer) {
      errors.studentAnswer = t('VALIDATION_ERROR');
    }

    if (
      values.student?.studentAnswer &&
      values.student?.studentAnswer === 'yes' &&
      isEmpty(values?.student?.destinationCity)
    ) {
      set(errors, 'student.destinationCity', t('VALIDATION_ERROR'));
    }

    return errors;
  };

  const onFilter = (reponse: any) => reponse.data.countries;

  const resultRenderer = (item: any) => (
    <div tw="flex space-x-4 items-center justify-start">
      <span>
        <img src={item.image} tw="w-6" alt="" />
      </span>
      <span>{item.label}</span>
    </div>
  );

  const onSubmit = async (values: any) => {
    //* TODO HACER aqui POST*/
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      updateUser({ user: values })(dispatchUser);
      push(`/${i18n.language}/finishing-sign-up/student/3`);
    }, 2000);
  };

  useEffect(() => {
    if (!localStorage.getItem('step-1-complete')) {
      push(`/${i18n.language}/finishing-sign-up`);
    } else if (!localStorage.getItem('step-2-complete')) {
      push(`/${i18n.language}/finishing-sign-up/user-type`);
    } else if (
      localStorage.getItem('step-1-complete') &&
      localStorage.getItem('step-2-complete')
    ) {
      if (userState?.user?.type?.id !== '15') {
        push(`/${i18n.language}/finishing-sign-up/user-type`);
      }
    }
  }, [localStorage]);

  const onTryAgainIfError = () => closePageError(dispatchPageError);

  return (
    <>
      <Header />
      {pagerErrorState.open ? (
        <PageErrorTemplate cb={onTryAgainIfError} />
      ) : (
        <main tw="p-4">
          <div tw="items-center mx-auto w-full mb-12 pt-6 max-w-screen-xl relative hidden md:flex ">
            <button
              type="button"
              tw="absolute left-0 cursor-pointer"
              onClick={onBackStep}>
              <MdKeyboardArrowLeft tw="text-icon text-neutral-dark3" />
            </button>
            <div tw="text-center  mx-auto" style={{ maxWidth: '400px' }}>
              <p tw="font-poppins-regular text-lg font-bold mt-3">
                {t('PAGE_TITLE_4')}
              </p>
              <p tw="font-barlow-regular text-base mt-3">
                {t('PAGE_SUBTITLE_4')}
              </p>
            </div>
            <div tw="absolute right-0">
              <Button onClick={onSkipStep} theme="skip" size="sm">
                {t('ACTION_SKIP_STEP')}
              </Button>
            </div>
          </div>
          <div tw="flex flex-wrap justify-between items-center mx-auto w-full mb-12 pt-6 max-w-screen-xl relative md:hidden ">
            <button type="button" tw="cursor-pointer" onClick={onBackStep}>
              <MdKeyboardArrowLeft tw="text-icon text-neutral-dark3" />
            </button>
            <div tw="ml-auto">
              <Button onClick={onSkipStep} theme="skip" size="sm">
                {t('ACTION_SKIP_STEP')}
              </Button>
            </div>
            <div tw="text-center w-full mt-6">
              <p tw="font-poppins-regular text-lg font-bold mt-3">
                {t('PAGE_TITLE_4')}
              </p>
              <p tw="font-barlow-regular text-base mt-3">
                {t('PAGE_SUBTITLE_4')}
              </p>
            </div>
          </div>
          <div tw="py-4">
            <Formik
              initialValues={userState?.user as User}
              validate={validate}
              enableReinitialize
              validateOnMount
              onSubmit={onSubmit}>
              {({
                values,
                errors,
                touched,
                isValid,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div tw="w-full mx-auto" style={{ maxWidth: '420px' }}>
                    <div tw="block space-y-2 sm:flex sm:space-x-2 sm:space-y-0 mb-2">
                      <div tw="w-full">
                        <RadioButton
                          value="yes"
                          label={t('LABEL_YES')}
                          name="student.studentAnswer"
                          tw="w-full"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values?.student?.studentAnswer === 'yes'}
                          theme={
                            values?.student?.studentAnswer === 'yes'
                              ? 'outlined-active'
                              : 'outlined-secondary'
                          }
                        />
                        {getIn(errors, 'student.studentAnswer') &&
                          getIn(touched, 'student.studentAnswer') && (
                            <span tw="text-brand-u-brand-error pt-2 block">
                              {getIn(errors, 'student.studentAnswer')}
                            </span>
                          )}
                      </div>
                      <div tw="w-full">
                        <RadioButton
                          value="no"
                          label={t('LABEL_DONT_KNOW')}
                          name="student.studentAnswer"
                          tw="w-full"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values?.student?.studentAnswer === 'no'}
                          theme={
                            values === 'no'
                              ? 'outlined-active'
                              : 'outlined-secondary'
                          }
                        />
                        {getIn(errors, 'student.studentAnswer') &&
                          getIn(touched, 'student.studentAnswer') && (
                            <span tw="text-brand-u-brand-error pt-2 block">
                              {getIn(errors, 'student.studentAnswer')}
                            </span>
                          )}
                      </div>
                    </div>
                    {values?.student?.studentAnswer === 'yes' && (
                      <div tw="mb-2 w-full">
                        <AsyncSearcher
                          isHidden={false}
                          name="student.destinationCity"
                          apiTarget={MoockonModel.moockerGetCountries}
                          onBlur={() => {
                            const e = {
                              target: { name: 'student.destinationCity' },
                            };
                            handleBlur(e);
                          }}
                          onResultSelect={(selectedOption: any) => {
                            const e = {
                              target: {
                                name: 'student.destinationCity',
                                value: selectedOption,
                              },
                            };
                            handleChange(e);
                          }}
                          resultRenderer={resultRenderer}
                          onFilter={onFilter}
                          placeholder=""
                          noOptionsMessage={t('LABEL_EMPTY_RESULTS')}
                          loadingMessage={t('ACTION_LOADIND_RESULTS')}
                          value={values?.student?.destinationCity}
                          icon={<MdSearch />}
                        />
                        {getIn(errors, 'student.destinationCity') &&
                          getIn(touched, 'student.destinationCity') && (
                            <span tw="text-brand-u-brand-error pt-2 block">
                              {getIn(errors, 'student.destinationCity')}
                            </span>
                          )}
                      </div>
                    )}
                    <div tw="mb-2 w-full">
                      <Button
                        type="submit"
                        theme="primary"
                        tw="w-full "
                        isDisabled={!isValid || isLoading}>
                        <span>{t('ACTION_CONTINUE')}</span>
                        {isLoading && (
                          <Spinner size="md" tw="ml-auto" theme="default" />
                        )}
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </main>
      )}
    </>
  );
};

export default AuthStudent2;
