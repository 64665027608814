import styled, { css } from 'styled-components/macro';
import tw from 'twin.macro';

type CheckBoxProps = {
  isDisabled: boolean;
  size: string;
  theme: string;
};

const variants: any = {
  primary: {
    idle: {
      before: tw`block border-2 border-neutral-white rounded`,
      after: tw`block absolute inset-2/4 left-0 origin-top-left invisible`,
    },
    checked: {
      before: tw`block border-2 border-neutral-white bg-transparent`,
      after: tw`visible text-neutral-white`,
    },
  },
  secondary: {
    idle: {
      before: tw`block border-2 border-brand-u-secondary-border-color rounded`,
      after: tw`block absolute inset-2/4 left-0 origin-top-left invisible`,
    },
    checked: {
      before: tw`border-brand-u-secondary-border-color`,
      after: tw`visible text-brand-u-secondary-border-color`,
    },
  },
  tertiary: {
    idle: {
      before: tw`block border-2 border-neutral-white rounded`,
      after: tw`block absolute inset-2/4 left-0 origin-top-left invisible`,
    },
    checked: {
      before: tw`border-neutral-white bg-brand-u-tertiary-color`,
      after: tw`visible text-brand-u-secondary-color`,
    },
  },
  active: {
    idle: {
      before: tw`block border-2 border-white rounded bg-neutral-white`,
      after: tw`block absolute inset-2/4 left-0 origin-top-left invisible`,
    },
    checked: {
      before: tw`border-neutral-white bg-neutral-white`,
      after: tw`visible text-brand-u-active-color`,
    },
  },
};

const checkboxDefaultVariant = (variant: string) => css`
  ${tw`relative flex items-center`}

  &::before {
    content: '';
    width: 1.5em;
    height: 1.5em;
    flex-shrink: 0;
    ${variants[variant].idle.before}
  }
  &::after {
    content: '';
    ${variants[variant].idle.after}
    transform: scaleX(-1) translate(-0.35em, 0em) rotate(135deg);
    border-right: 3px solid currentColor;
    border-top: 3px solid currentColor;
    height: 0.7em;
    width: 0.35em;
  }
`;

const checkBoxDefaultVariantChecked = (variant: string) => css`
  &::before {
    ${variants[variant]?.checked?.before && variants[variant].checked.before}
  }
  &::after {
    ${variants[variant]?.checked?.after && variants[variant].checked.after}
  }
`;

export default styled.label<CheckBoxProps>`
  ${({ isDisabled }) =>
    isDisabled ? tw`cursor-not-allowed opacity-70` : tw`cursor-pointer`};

  & {
    font-size: 1em;
    ${({ size }) => size === 'sm' && css`font-size: 0.85em;`};
    ${({ size }) => size === 'md' && css`font-size: 1em;`};
    ${({ size }) => size === 'lg' && css`font-size: 1.125em;`};
    ${({ size }) => size === 'xl' && css`font-size: 1.225em;`};

    ${tw`relative inline-flex items-center select-none`}
    input {
      ${tw`appearance-none absolute w-full`}
      &:checked ~ span {
        ${checkBoxDefaultVariantChecked('secondary')}
      }
    }
    > span {
      ${checkboxDefaultVariant('secondary')};
    }

    [data-label] {
      ${tw`ml-2`}
    }
  }

  ${({ theme }) =>
    theme === 'outlined-primary' &&
    css`
      & {
        ${tw`bg-brand-u-primary-color border-2 border-brand-u-primary-border-color p-3.5 rounded`}
        input {
          ${tw`appearance-none absolute w-full`}
          &:checked ~ span {
            ${checkBoxDefaultVariantChecked('primary')}
          }
        }
        > span {
          ${checkboxDefaultVariant('primary')};
        }
        [data-label] {
          ${tw`text-neutral-white`}
        }
      }
    `};

  ${({ theme }) =>
    theme === 'outlined-secondary' &&
    css`
      & {
        ${tw`bg-brand-u-secondary-color border-2 border-brand-u-secondary-border-color p-3.5 rounded`}
        input {
          ${tw`appearance-none absolute w-full`}
          &:checked ~ span {
            ${checkBoxDefaultVariantChecked('secondary')}
          }
        }
        > span {
          ${checkboxDefaultVariant('secondary')};
        }
        [data-label] {
          ${tw`text-brand-u-secondary-text-color`}
        }
      }
    `};

  ${({ theme }) =>
    theme === 'outlined-tertiary' &&
    css`
      & {
        ${tw`bg-brand-u-tertiary-color border-2 border-brand-u-tertiary-border-color p-3.5 rounded`}
        input {
          ${tw`appearance-none absolute w-full`}
          &:checked ~ span {
            ${checkBoxDefaultVariantChecked('tertiary')}
          }
        }
        > span {
          ${checkboxDefaultVariant('tertiary')};
        }
        [data-label] {
          ${tw`text-neutral-white`}
        }
      }
    `};

  ${({ theme }) =>
    theme === 'outlined-active' &&
    css`
      & {
        ${tw`bg-brand-u-active-color border-2 border-brand-u-active-color p-3.5 rounded`}
        input {
          ${tw`appearance-none absolute w-full`}
          &:checked ~ span {
            ${checkBoxDefaultVariantChecked('active')}
          }
        }
        > span {
          ${checkboxDefaultVariant('active')};
        }
        [data-label] {
          ${tw`text-neutral-white`}
        }
      }
    `};
`;
