import Axios from 'axios';
import Config from '../config';
import TokenService from './token.service';

Axios.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 418 && !originalConfig.retry) {
        originalConfig.retry = true;

        try {

          // TODO PENDING API
          const rs = await Axios.post(`${Config.server.api.base}/refresh`, {
            refreshToken: TokenService.getLocalRefreshToken(),
          });

          const { accessToken } = rs.data;
          TokenService.updateLocalAccessToken(accessToken);
          return await Axios(originalConfig);

        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);

const getOptions = (requireToken, isBlob, isFile) => {
  const options = {};

  if (requireToken && TokenService.getLocalAccessToken()) {
    options.headers = isFile
      ? {
        'x-access-token': TokenService.getLocalAccessToken(),
        'Content-Type': 'multipart/form-data',
      }
      : { 'x-access-token': TokenService.getLocalAccessToken() };
  }

  if (isBlob) {
    options.responseType = 'blob';
  }

  return options;
};

const get = (apiEndpoint, requireToken) =>
  Axios.get(apiEndpoint, getOptions(requireToken)).catch((err) => {
    throw err.response && err.response.status;
  });
const getBlob = (apiEndpoint, requireToken) =>
  Axios.get(apiEndpoint, getOptions(requireToken, true)).catch((err) => {
    throw err.response && err.response.status;
  });
const post = (apiEndpoint, payload, requireToken) =>
  Axios.post(apiEndpoint, payload, getOptions(requireToken)).catch((err) => {
    throw err.response && err.response.status;
  });
const put = (apiEndpoint, payload, requireToken) =>
  Axios.put(apiEndpoint, payload, getOptions(requireToken)).catch((err) => {
    throw err.response && err.response.status;
  });
const patch = (apiEndpoint, payload, requireToken) =>
  Axios.patch(apiEndpoint, payload, getOptions(requireToken)).catch((err) => {
    throw err.response && err.response.status;
  });
const deleting = (apiEndpoint, requireToken) =>
  Axios.delete(apiEndpoint, getOptions(requireToken)).catch((err) => {
    throw err.response;
  });
const postFile = (apiEndpoint, payload, requireToken) =>
  Axios.post(apiEndpoint, payload, getOptions(requireToken, false, true)).catch(
    (err) => {
      throw err.response && err.response.status;
    },
  );

const AxiosService = {
  get,
  getBlob,
  post,
  put,
  patch,
  deleting,
  postFile,
};

export default AxiosService; 