import { FC, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Formik, getIn } from 'formik';
import { MdKeyboardArrowLeft, MdSearch } from 'react-icons/md';
import Button from '../../components/Button/Button';
import 'twin.macro';
import Header from '../../components/Header/Header';
import AsyncSearcher from '../../components/AsyncSearcher/AsyncSearcher';
import MoockonModel from '../../data/models/MockoonModel';
import updateUser from '../../data/context/user/actions/user/updateUser';
import Spinner from '../../components/Spinner/Spinner';
import userContext from '../../data/context/user/UserContext';
import ROLE_TYPES from '../../config/constants/roleTypes';
import { User } from '../../models';
import useFetch from '../../hooks/useFetch';
import openModal from '../../data/context/modal/actions/openModal';
import LoadingModal from '../../components/Modals/components/LoadingModal/LoadingModal';
import closeModal from '../../data/context/modal/actions/closeModal';
import openPageError from '../../data/context/pageError/actions/openPageError';
import modalContext from '../../data/context/modal/ModalProvider';
import { PageErrorContext } from '../../data/context/pageError/PageErrorProvider';
import closePageError from '../../data/context/pageError/actions/closePageError';
import PageErrorTemplate from '../../components/PageError/PageError';

const AuthStudent3: FC = () => {
  const { userState, dispatchUser } = useContext(userContext);
  const { dispatchModal } = useContext(modalContext);
  const { pagerErrorState, dispatchPageError } = useContext(PageErrorContext);
  const [isLoading, setIsLoading] = useState(false);
  const { push } = useHistory();
  const { i18n, t } = useTranslation();
  const [onFetch] = useFetch(
    () => {
      openModal({
        template: <LoadingModal />,
      })(dispatchModal);
    },
    () => {
      closeModal(dispatchModal);
    },
    (payload: any) => {
      updateUser({ user: payload.data })(dispatchUser);
    },
    () => {
      openPageError(dispatchPageError);
    },
    MoockonModel.moockerGetUser,
  );

  useEffect(() => {
    if (!isEmpty(userState?.user)) return;
    onFetch();
  }, [userState?.user]);

  useEffect(() => () => closeModal(dispatchModal), []);

  const onSkipStep = () =>
    push(`/${i18n.language}/finishing-sign-up/upload-photo`, {
      from: ROLE_TYPES.UNKNOW_STUDENT,
    } as { from: string });

  const onBackStep = () =>
    push(`/${i18n.language}/finishing-sign-up/student/2`);

  const validationSchema = Yup.object({
    student: Yup.object().shape({
      destinationUniversity: Yup.object()
        .required(t('VALIDATION_ERROR'))
        .nullable(),
    }),
  });

  const onFilter = (reponse: any) => reponse.data.countries;

  const resultRenderer = (item: any) => (
    <div tw="flex space-x-4 items-center justify-start">
      <span>
        <img src={item.image} tw="w-6" alt="" />
      </span>
      <span>{item.label}</span>
    </div>
  );

  const onSubmit = async (values: any) => {
    //* TODO HACER aqui POST*/
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      updateUser({ user: values })(dispatchUser);
      push(`/${i18n.language}/finishing-sign-up/upload-photo`, {
        from: ROLE_TYPES.UNKNOW_STUDENT,
      } as { from: string });
    }, 2000);
  };

  useEffect(() => {
    if (!localStorage.getItem('step-1-complete')) {
      push(`/${i18n.language}/finishing-sign-up`);
    } else if (!localStorage.getItem('step-2-complete')) {
      push(`/${i18n.language}/finishing-sign-up/user-type`);
    } else if (
      localStorage.getItem('step-1-complete') &&
      localStorage.getItem('step-2-complete')
    ) {
      if (userState?.user?.type?.id !== '15') {
        push(`/${i18n.language}/finishing-sign-up/user-type`);
      }
    }
  }, [localStorage]);

  const onTryAgainIfError = () => closePageError(dispatchPageError);

  return (
    <>
      <Header />
      {pagerErrorState.open ? (
        <PageErrorTemplate cb={onTryAgainIfError} />
      ) : (
        <main tw="p-4">
          <div tw="items-center mx-auto w-full mb-12 pt-6 max-w-screen-xl relative hidden md:flex ">
            <button
              type="button"
              tw="absolute left-0 cursor-pointer"
              onClick={onBackStep}>
              <MdKeyboardArrowLeft tw="text-icon text-neutral-dark3" />
            </button>
            <div tw="text-center  mx-auto">
              <p tw="font-poppins-regular text-lg font-bold mt-3">
                {t('PAGE_TITLE_5')}
              </p>
              <p tw="font-barlow-regular text-base mt-3">
                {t('PAGE_SUBTITLE_5')}
              </p>
            </div>
            <div tw="absolute right-0">
              <Button onClick={onSkipStep} theme="skip" size="sm">
                {t('ACTION_SKIP_STEP')}
              </Button>
            </div>
          </div>
          <div tw="flex flex-wrap justify-between items-center mx-auto w-full mb-12 pt-6 max-w-screen-xl relative md:hidden ">
            <button type="button" tw="cursor-pointer" onClick={onBackStep}>
              <MdKeyboardArrowLeft tw="text-icon text-neutral-dark3" />
            </button>
            <div>
              <Button onClick={onSkipStep} theme="skip" size="sm">
                {t('ACTION_SKIP_STEP')}
              </Button>
            </div>
            <div tw="text-center w-full mt-6">
              <p tw="font-poppins-regular text-lg font-bold mt-3">
                {t('PAGE_TITLE_5')}
              </p>
              <p tw="font-barlow-regular text-base mt-3">
                {t('PAGE_SUBTITLE_5')}
              </p>
            </div>
          </div>
          <div tw="py-4">
            <Formik
              initialValues={userState?.user as User}
              validationSchema={validationSchema}
              enableReinitialize
              validateOnMount
              onSubmit={onSubmit}>
              {({
                values,
                errors,
                touched,
                isValid,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div tw="w-full flex flex-wrap mx-auto max-w-form-content-max">
                    <div tw="mb-2 w-full text-left sm:w-3/12 sm:text-right sm:mb-4 pr-3">
                      <span>{t('LABEL_DESTINATION_UNIVERSITY')}</span>
                    </div>
                    <div tw="mb-2 w-full sm:w-9/12 md:w-6/12 sm:mb-4">
                      <AsyncSearcher
                        isHidden={false}
                        name="student.destinationUniversity"
                        apiTarget={MoockonModel.moockerGetCountries}
                        onBlur={() => {
                          const e = {
                            target: { name: 'student.destinationUniversity' },
                          };
                          handleBlur(e);
                        }}
                        onResultSelect={(selectedOption: any) => {
                          const e = {
                            target: {
                              name: 'student.destinationUniversity',
                              value: selectedOption,
                            },
                          };
                          handleChange(e);
                        }}
                        resultRenderer={resultRenderer}
                        onFilter={onFilter}
                        placeholder=""
                        noOptionsMessage={t('LABEL_EMPTY_RESULTS')}
                        loadingMessage={t('ACTION_LOADIND_RESULTS')}
                        value={values?.student?.destinationUniversity}
                        icon={<MdSearch />}
                      />
                      {getIn(errors, 'student.destinationUniversity') &&
                        getIn(touched, 'student.destinationUniversity') && (
                          <span tw="text-brand-u-brand-error pt-2 block">
                            {getIn(errors, 'student.destinationUniversity')}
                          </span>
                        )}
                    </div>
                    <div tw="hidden md:block w-3/12 mb-4" />
                    <div tw="mb-2 w-full text-left sm:w-3/12 sm:text-right sm:mb-4" />
                    <div tw="mb-2 w-full sm:w-9/12 md:w-6/12 sm:mb-4">
                      <Button
                        type="submit"
                        theme="primary"
                        tw="w-full sm:w-6/12"
                        isDisabled={!isValid || isLoading}>
                        <span>{t('ACTION_CONTINUE')}</span>
                        {isLoading && (
                          <Spinner size="md" tw="ml-auto" theme="default" />
                        )}
                      </Button>
                    </div>
                    <div tw="hidden md:block w-3/12 mb-4" />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </main>
      )}
    </>
  );
};

export default AuthStudent3;
