import {
  USER_LOAD_ERROR,
  USER_UPDATE,
} from '../../../../../config/constants/actionTypes';

const userReducer = (state: { user: any }, { payload, type }: any) => {
  switch (type) {
    case USER_LOAD_ERROR: {
      return {
        ...state,
        user: {
          ...state.user,
        },
      };
    }

    case USER_UPDATE: {
      return {
        ...state,
        user: {
          ...payload.user,
        },
      };
    }

    default:
      return state;
  }
};

export default userReducer;
