import { PAGE_ERROR_CLOSE } from '../../../../config/constants/actionTypes';

export default (
  dispatch: (arg0: { type: string; payload: { open: boolean } }) => void,
) => {
  dispatch({
    type: PAGE_ERROR_CLOSE,
    payload: { open: false },
  });
};
