import { FC } from 'react';
import { WrapperHeader, HeaderContent } from './styles';
import Logo from '../../assets/img/header_logo.png';

const Header: FC = () => (
  <>
    <WrapperHeader>
      <HeaderContent>
        <img src={Logo} alt="erasmusu" />
      </HeaderContent>
    </WrapperHeader>
  </>
);

export default Header;
