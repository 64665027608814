module.exports = {
    debugMode: true,
    server: {
        api: {
            base: 'http://api.accounts.erasmusu.net/api/v1',
            moocker: 'http://localhost:3030',
            timeout: 20000,
            headTimeout: 2000
        }
    }
};