import {
  PAGE_ERROR_OPEN,
  PAGE_ERROR_CLOSE,
} from '../../../../config/constants/actionTypes';

const pageErrorReducer = (state: any, { payload, type }: any) => {
  switch (type) {
    case PAGE_ERROR_OPEN:
    case PAGE_ERROR_CLOSE:
      return { ...payload };
    default:
      return state;
  }
};

export default pageErrorReducer;
