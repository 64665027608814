import styled, { keyframes, css } from 'styled-components';
import tw from 'twin.macro';

type SpinnerProps = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  theme?: 'active' | 'default';
};

const rotateAnimation = keyframes`
 to { transform: rotate(360deg); }
`;

const activeTheme = `
    &::before{
        border-top-color: rgba(13, 139, 255, 1);
        border-left-color: rgba(13, 139, 255, 1);
    }
    
    &::after {
        border: 4px solid rgba(13, 139, 255, 0.25);
    }
`;

const defaultTheme = `
    &::before{
        border-top-color: rgba(255, 255, 255, 1);
        border-left-color: rgba(255, 255, 255, 1);
    }
    &::after {
        border: 4px solid rgba(255, 255, 255, 0.25);
    }
`;

export default styled.div<SpinnerProps>`
  ${tw`rounded-full h-10 w-10 ease-linear flex-shrink-0 relative`};

  &::before,
  &::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    top: 0;
    left: 0;
  }
  &::before {
    width: 100%;
    height: 100%;
    border: 4px solid transparent;
    animation: ${rotateAnimation} 1.5s linear infinite;
  }
  &::after {
    width: 100%;
    height: 100%;
  }

  ${({ theme }) =>
    theme === 'active' &&
    css`
      ${activeTheme};
    `};
  ${({ theme }) =>
    theme === 'default' &&
    css`
      ${defaultTheme};
    `};

  ${({ size }) => size === 'sm' && tw`h-4 w-4`};
  ${({ size }) => size === 'md' && tw`h-5 w-5`};
  ${({ size }) => size === 'lg' && tw`h-8 w-8`};
  ${({ size }) => size === 'xl' && tw`h-10 w-10`};
`;
