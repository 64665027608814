import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import commonEs from '../locales/es/common.json';
import commonEn from '../locales/en/common.json';
import commonDe from '../locales/de/common.json';
import commonFr from '../locales/fr/common.json';
import commonIt from '../locales/it/common.json';
import commonNl from '../locales/nl/common.json';
import commonPl from '../locales/pl/common.json';
import commonPt from '../locales/pt/common.json';
import commonTr from '../locales/tr/common.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'es',
    load: 'languageOnly',
    nonExplicitSupportedLngs: false,
    whitelist: ['es', 'en', 'de', 'fr', 'it', 'nl', 'pl', 'pt', 'tr'],
    supportedLngs: ['es', 'en', 'de', 'fr', 'it', 'nl', 'pl', 'pt', 'tr'],
    resources: {
      es: { common: commonEs },
      en: { common: commonEn },
      de: { common: commonDe },
      fr: { common: commonFr },
      it: { common: commonIt },
      nl: { common: commonNl },
      pl: { common: commonPl },
      pt: { common: commonPt },
      tr: { common: commonTr },
    },
    defaultNS: 'common',
    detection: {
      order: ['path', 'cookie', 'localStorage', 'navigator'],
      lookupFromPathIndex: 0,
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;