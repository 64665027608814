import { FC, useContext } from 'react';

import 'twin.macro';
import Header from '../../components/Header/Header';

import { PageErrorContext } from '../../data/context/pageError/PageErrorProvider';
import PageErrorTemplate from '../../components/PageError/PageError';
import closePageError from '../../data/context/pageError/actions/closePageError';

import SignupForm from '../../components/SignupForm/SignupForm';

const AuthSignUp: FC = () => {
  const pageError = useContext(PageErrorContext);

  const onTryAgainIfError = () => closePageError(pageError.dispatchPageError);

  return (
    <>
      <Header />
      {pageError.pagerErrorState.open ? (
        <PageErrorTemplate cb={onTryAgainIfError} />
      ) : (
        <main tw="p-4">
          <div tw="py-4">
            <SignupForm />
          </div>
        </main>
      )}
    </>
  );
};

export default AuthSignUp;
