import styled from 'styled-components';
import tw from 'twin.macro';

export default styled.label`
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  
  ${tw`text-base text-neutral-white bg-brand-u-primary-color border-2 border-brand-u-primary-border-color leading-5 rounded`}
`;

export const InputFile = styled.input`
  ${tw`hidden`}  
`;
