import { useEffect, useState, Dispatch, SetStateAction } from 'react';

type ReturnType<T> = [
  T | undefined,
  Dispatch<SetStateAction<T | undefined>>
]

export default <T,>(key: string, initialValue?: T): ReturnType<T> => {
  const [state, setState] = useState<T | undefined>(() => {
    if (!initialValue) return;

    try {
      const value = localStorage.getItem(key);

      // eslint-disable-next-line consistent-return
      return value ? JSON.parse(value) : initialValue;
    } catch (error) {
      throw new Error();
    }
  });

  useEffect(() => {
    if(state) {
      try {
        localStorage.setItem(key, JSON.stringify(state));
      } catch (error) {
        throw new Error();
      }
    }
  }, [state])

  return [state, setState];
};