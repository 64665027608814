import styled, { css } from 'styled-components';
import tw from 'twin.macro';

type InputProps = {
  iconPosition: string;
};

export const StyledIcon = styled.div`
  ${tw`absolute flex items-center h-full`}
  font-size:1.5em;
  padding: 0 0.5em;
`;

export const StyledInput = styled.div<InputProps>`
  & {
    ${tw`inline-flex relative`}
    input {
      flex: 1 0 auto;
      ${tw`rounded p-3.5 outline-none bg-brand-u-secondary-color border-2 border-brand-u-secondary-border-color text-brand-u-secondary-text-color max-w-full`};
      :disabled {
        ${tw`cursor-not-allowed opacity-70 border-brand-u-secondary-color`}
      }

      ${({ iconPosition }) =>
        iconPosition === 'right'
          ? css`
              padding-right: 2.2em;
              ~ ${StyledIcon} {
                ${tw`right-0`}
              }
            `
          : css`
              padding-left: 2.2em;
              ~ ${StyledIcon} {
                ${tw`left-0`}
              }
            `}
    }
  }
`;
