import styled from 'styled-components';
import tw from 'twin.macro';

export default styled.div`
  max-width: 500px;
  ${tw`w-full bg-white relative p-8 shadow-lg rounded-lg flex flex-col items-start text-lg text-gray-800`};
`;

export const LoadingSpinnerWrapper = styled.div`
  ${tw`w-full flex justify-center items-center pt-6`};
`;
