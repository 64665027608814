import styled from 'styled-components';
import AsyncSelect from 'react-select/async';
import tw from 'twin.macro';

export default styled(AsyncSelect)`
  && {
    .filter__control {
      ${tw`rounded outline-none bg-brand-u-secondary-color border-2 border-brand-u-secondary-border-color text-brand-u-secondary-text-color max-w-full`};
      height: 56px;
    }
  }
`;
