import { FC, useContext, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Formik, getIn } from 'formik';
import Button from '../../components/Button/Button';
import 'twin.macro';
import Header from '../../components/Header/Header';
import RadioButton from '../../components/RadioButton/RadioButton';
import userContext from '../../data/context/user/UserContext';
import ROLE_TYPES from '../../config/constants/roleTypes';
import Spinner from '../../components/Spinner/Spinner';
import updateUser from '../../data/context/user/actions/user/updateUser';
import { User } from '../../models';
import useLocalStorage from '../../hooks/useLocalStorage';
import useFetch from '../../hooks/useFetch';
import openModal from '../../data/context/modal/actions/openModal';
import LoadingModal from '../../components/Modals/components/LoadingModal/LoadingModal';
import closeModal from '../../data/context/modal/actions/closeModal';
import openPageError from '../../data/context/pageError/actions/openPageError';
import MoockonModel from '../../data/models/MockoonModel';
import modalContext from '../../data/context/modal/ModalProvider';
import { PageErrorContext } from '../../data/context/pageError/PageErrorProvider';
import PageErrorTemplate from '../../components/PageError/PageError';
import closePageError from '../../data/context/pageError/actions/closePageError';

const AuthUserType: FC = () => {
  const { push } = useHistory();
  const { userState, dispatchUser } = useContext(userContext);
  const { dispatchModal } = useContext(modalContext);
  const { pagerErrorState, dispatchPageError } = useContext(PageErrorContext);

  const [isLoading, setIsLoading] = useState(false);
  const { i18n, t } = useTranslation();
  const [, setLS] = useLocalStorage('step-2-complete');
  const [onFetch] = useFetch(
    () => {
      openModal({
        template: <LoadingModal />,
      })(dispatchModal);
    },
    () => {
      closeModal(dispatchModal);
    },
    (payload: any) => {
      updateUser({ user: payload.data })(dispatchUser);
    },
    () => {
      openPageError(dispatchPageError);
    },
    MoockonModel.moockerGetUser,
  );

  useEffect(() => {
    if (!isEmpty(userState?.user)) return;
    onFetch();
  }, [userState?.user]);

  useEffect(() => () => closeModal(dispatchModal), []);

  const TARGET_PATH = useMemo(
    () => ({
      [ROLE_TYPES.UNKNOW_STUDENT]: `/${i18n.language}/finishing-sign-up/student/1`,
      [ROLE_TYPES.LANDLORD]: `/${i18n.language}/finishing-sign-up/landlord/1`,
      [ROLE_TYPES.OTHER]: `/${i18n.language}/finishing-sign-up/upload-photo`,
    }),
    [i18n.language],
  );

  const validationSchema = Yup.object({
    type: Yup.object().shape({
      id: Yup.string().required(t('VALIDATION_ERROR')),
    }),
  });

  //* TODO Comprobar si puede estar en esta página*/
  const onSubmit = async (values: any) => {
    // Reset de student or landlord

    //* TODO HACER aqui POST*/
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      updateUser({ user: values })(dispatchUser);
      setLS(true);
      push(TARGET_PATH[values?.type?.id], {
        from: values?.type?.id === ROLE_TYPES.OTHER ? ROLE_TYPES.OTHER : null,
      });
    }, 2000);
  };

  const onBackStep = () => push(`/${i18n.language}/finishing-sign-up`);
  const onTryAgainIfError = () => closePageError(dispatchPageError);

  return (
    <>
      <Header />
      {pagerErrorState.open ? (
        <PageErrorTemplate cb={onTryAgainIfError} />
      ) : (
        <main tw="p-4">
          <div tw="items-center mx-auto w-full mb-12 pt-6 max-w-screen-xl relative hidden md:flex ">
            <button
              type="button"
              tw="absolute left-0 cursor-pointer"
              onClick={onBackStep}>
              <MdKeyboardArrowLeft tw="text-icon text-neutral-dark3" />
            </button>
            <div tw="text-center  mx-auto" style={{ maxWidth: '400px' }}>
              <p tw="font-poppins-regular text-lg font-bold mt-3">
                {t('PAGE_TITLE_2', { param1: 'Sara' })}
              </p>
              <p tw="font-barlow-regular text-base mt-3">
                {t('PAGE_SUBTITLE_2')}
              </p>
            </div>
          </div>
          <div tw="flex flex-wrap justify-between items-center mx-auto w-full mb-12 pt-6 max-w-screen-xl relative md:hidden ">
            <button type="button" tw="cursor-pointer" onClick={onBackStep}>
              <MdKeyboardArrowLeft tw="text-icon text-neutral-dark3" />
            </button>
            <div tw="text-center w-full mt-6">
              <p tw="font-poppins-regular text-lg font-bold mt-3">
                {t('PAGE_TITLE_2', { param1: 'Sara' })}
              </p>
              <p tw="font-barlow-regular text-base mt-3">
                {t('PAGE_SUBTITLE_2')}
              </p>
            </div>
          </div>
          <div tw="py-4">
            <Formik
              initialValues={userState?.user as User}
              validationSchema={validationSchema}
              enableReinitialize
              validateOnMount
              onSubmit={onSubmit}>
              {({
                values,
                errors,
                touched,
                isValid,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div
                    tw="w-full space-y-2 mx-auto"
                    style={{ maxWidth: '400px' }}>
                    <div>
                      <RadioButton
                        value={ROLE_TYPES.UNKNOW_STUDENT}
                        label={t('LABEL_BE_STUDENT')}
                        tw="w-full"
                        name="type.id"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        checked={values?.type?.id === ROLE_TYPES.UNKNOW_STUDENT}
                        theme={
                          values?.type?.id === ROLE_TYPES.UNKNOW_STUDENT
                            ? 'outlined-active'
                            : 'outlined-secondary'
                        }
                      />
                      {getIn(errors, 'type.id') &&
                        getIn(touched, 'type.id') && (
                          <span tw="text-brand-u-brand-error pt-2 block">
                            {getIn(errors, 'type.id')}
                          </span>
                        )}
                    </div>
                    <div>
                      <RadioButton
                        value={ROLE_TYPES.LANDLORD}
                        label={t('LABEL_BE_OWNER')}
                        tw="w-full"
                        name="type.id"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        checked={values?.type?.id === ROLE_TYPES.LANDLORD}
                        theme={
                          values?.type?.id === ROLE_TYPES.LANDLORD
                            ? 'outlined-active'
                            : 'outlined-secondary'
                        }
                      />
                      {getIn(errors, 'type.id') &&
                        getIn(touched, 'type.id') && (
                          <span tw="text-brand-u-brand-error pt-2 block">
                            {getIn(errors, 'type.id')}
                          </span>
                        )}
                    </div>
                    <div>
                      <RadioButton
                        value={ROLE_TYPES.OTHER}
                        label={t('LABEL_BE_OTHER')}
                        tw="w-full"
                        name="type.id"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        checked={values?.type?.id === ROLE_TYPES.OTHER}
                        theme={
                          values?.type?.id === ROLE_TYPES.OTHER
                            ? 'outlined-active'
                            : 'outlined-secondary'
                        }
                      />
                      {getIn(errors, 'type.id') &&
                        getIn(touched, 'type.id') && (
                          <span tw="text-brand-u-brand-error pt-2 block">
                            {getIn(errors, 'type.id')}
                          </span>
                        )}
                    </div>
                    <div>
                      <Button
                        type="submit"
                        theme="primary"
                        tw="w-full"
                        isDisabled={!isValid || isLoading}>
                        <span>{t('ACTION_CONTINUE')}</span>
                        {isLoading && (
                          <Spinner size="md" tw="ml-auto" theme="default" />
                        )}
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </main>
      )}
    </>
  );
};

export default AuthUserType;
