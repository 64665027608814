const getLocalRefreshToken = () => {
    const refreshToken = JSON.parse(localStorage.getItem("refresh-token"));
    return refreshToken;
};

const getLocalAccessToken = () => {
    const accessToken = localStorage.getItem("access-token") && JSON.parse(localStorage.getItem("access-token"));
    return accessToken;
};

const updateLocalAccessToken = (token) => {
    let accessToken = JSON.parse(localStorage.getItem("access-token"));
    accessToken = token;
    localStorage.setItem("access-token", JSON.stringify(accessToken));
};

const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
};

export default TokenService;