import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../Spinner/Spinner';

import Styled, { LoadingSpinnerWrapper } from './styles';
import 'twin.macro';

const LoadingModal: FC = () => {
  const { t } = useTranslation();

  return (
    <Styled>
      <p tw="text-base mb-5 font-poppins-regular">{t('MODAL_LOADING_TITLE')}</p>
      <p tw="text-2xl font-bold font-poppins-regular">
        {t('MODAL_LOADING_SUBTITLE')}
      </p>
      <LoadingSpinnerWrapper>
        <Spinner size="xl" />
      </LoadingSpinnerWrapper>
    </Styled>
  );
};

export default LoadingModal;
