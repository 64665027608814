import { AxiosResponse } from 'axios';
import { useEffect, useRef } from 'react';

const useFetch = (
  onLoading?: { (): void },
  onEnd?: { (): void },
  onSuccess?: { (payload: any): void },
  onError?: { (errorMessage?: any): void },
  endpoint?: { (): Promise<AxiosResponse<any> | Promise<void>> },
) => {
  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef<boolean>(false);

  useEffect(
    () => () => {
      cancelRequest.current = true;
    },
    [],
  );

  const onFetch = async (): Promise<void> => {
    if (cancelRequest.current) return;

    try {
      if (typeof onLoading === 'function') {
        onLoading();
      }

      if (typeof endpoint === 'function' && typeof onSuccess === 'function') {
        const response = await endpoint();
        onSuccess(response);
      }
    } catch (error: any) {
      if (typeof onError === 'function') {
        onError(error);
      }
    } finally {
      if (typeof onEnd === 'function') {
        onEnd();
      }
    }
  };

  return [onFetch];
};

export default useFetch;
